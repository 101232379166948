<template>
  <!-- Main content -->
  <div class="main-content">
    <!-- Header -->
    <div class="header bg-gradient-dark py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-2">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Verify Email!</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Enter the 4 digit code sent to your email</small>
              </div>
              <form v-on:submit.prevent="submitForm">
                <div class="form-group mb-3">
                  <div class="input-group input-group-merge input-group-alternative">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                    </div>
                    <input class="form-control" placeholder="Email" type="email" id="email" v-model="form.email">
                  </div>
                </div>
                <div class="form-group mb-3">
                  <div class="input-group input-group-merge input-group-alternative">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class=""></i></span>
                    </div>
                    <input class="form-control" placeholder="Enter 4 digit code" type="text" id="code" v-model="form.code">
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary my-4">Verify</button>
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button v-on:click="resendCode" class="btn btn-outline-secondary">Request New Code</button>
            </div>
          </div>
        </div>        
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'VerifyEmail',
  components: {
  },
  data() {
        return{
            form: {
                code: '',
                email: localStorage.myemail,
            },
            myemail: '',
            submitting: false,
            isDisabled: false,
        }
  },
  mounted() {
  },
  computed: {
        axiosSubmitFormParams() {
            const params = new URLSearchParams();
            params.append('email', this.form.email);
            params.append('code', this.form.code);
            return params;
        },
        axiosResendCodeParams() {
            const params = new URLSearchParams();
            params.append('email', this.form.email);
            return params;
        }
  },
  methods:{
        submitForm(){
           this.isDisabled = true
            axios.get(process.env.VUE_APP_AUTHURL+'/email/verify',{
                    params: this.axiosSubmitFormParams
                })
                .then((res) => {
                    //Perform Success Action
                    if (res.status == '200') {
                        this.$router.push('login');
                    }
                })
                .catch((error) => {
                    // error.response.status Check status code
                    this.$moshaToast(error.response.data.message,{
                        type: 'danger'
                    });
                }).finally(() => {
                    //Perform other dependent actions
                    this.isDisabled = false
                });
        },
        resendCode(){
            axios.get(process.env.VUE_APP_AUTHURL+'/email/verify/code',{
                    params: this.axiosResendCodeParams
                })
                .then((res) => {
                    //Perform Success Action
                    if (res.status == '200') {
                        this.$moshaToast(res.message,{
                            type: 'success'
                        });
                    }
                })
                .catch((error) => {
                    // error.response.status Check status code
                    this.$moshaToast(error.response.data.message,{
                        type: 'danger'
                    });
                });            
        }
    },
}
</script>

